var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',[_c('el-col',{staticStyle:{"height":"100vh"},attrs:{"span":10}},[_c('el-card',{attrs:{"body-style":{ height:'80vh' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("成员排行榜")])]),_c('com-table',{ref:"acTable",attrs:{"data":{
                url:'/xapi/user.group/memberRank',
                params:{..._vm.$route.params,type:2}
              },"pageOptions":false,"default-sort":{prop: 'right_count', order: 'descending'},"parseMethod":({data} , next)=>{next(data.data,data.total)},"pageSize":20,"height":"78vh","rowStyle":()=>{return {cursor:'pointer'};},"columns":[
              {prop:'realname',label:'用户名'},
              {prop:'right_count',label:'全部AC题目数',width:'160' , align:'center' , sortable:true},
              {prop:'right_lv',label:'测试点得分率',width:'160' , align:'center'},
          ]},on:{"sort-change":({prop,order})=>{_vm.$refs.acTable.reset({[prop+'_ord']:order==='descending'? 2 : 1});}}})],1),_c('el-card',{attrs:{"body-style":{ height:'30vh' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("团队数据统计")])]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('p',[_vm._v("评测提交题目数："+_vm._s(_vm.groupState.quest_count || 0))]),_c('p',[_vm._v("AC提交数："+_vm._s(_vm.groupState.right_count || 0))])]),_c('el-col',{attrs:{"span":12}},[_c('p',[_vm._v("评测提交次数："+_vm._s(_vm.groupState.total_count || 0))]),_c('p',[_vm._v("AC测试点数："+_vm._s(_vm.groupState.right_lv || 0))])])],1)],1)],1),_c('el-col',{attrs:{"span":13,"offset":1}},[_c('el-card',{staticClass:"show-border",attrs:{"body-style":{ padding: '0 0 20px 0',height:'80vh' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("团员动态")])]),_c('form-create',{staticStyle:{"padding":"0 20px"},attrs:{"inline":true,"label-width":'40px',"components":[
            {name:'form-item',props:{prop:'type',label:'类型' , type:'select', multiple:true,filterable:false,data:{ url:'/xapi/user.group/options/type/trend_type' }
              }
            },
            {name:'form-item',props:{prop:'user_id',label:'团员' , type:'select', multiple:true,remote:true,filterable:true,data:{
                    url:'/xapi/user.group/memberList'
                    ,params:{..._vm.$route.params}
                    ,labelOptions:{label:'realname',value:'user_id'}
                }
              }
            },
        ]},model:{value:(_vm.trendWhere),callback:function ($$v) {_vm.trendWhere=$$v},expression:"trendWhere"}},[_c('el-form-item',{staticClass:"operate",attrs:{"slot":"after"},slot:"after"},[_c('el-button',{staticStyle:{"--color-primary":"#fc8563","--color-primary-8":"#fc8563e5"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$refs.trendTable.reset(_vm.trendWhere)}}},[_vm._v("查询")])],1)],1),_c('com-table',{ref:"trendTable",attrs:{"columns":[],"data":{
        url:'/xapi/user.group/trendList'
      },"height":"76vh","list-type":'list',"border":""},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',{staticStyle:{"display":"flex","height":"60px","line-height":"30px","padding":"0 20px"}},[_c('el-avatar',{staticStyle:{"display":"block","margin-top":"10px","margin-right":"10px"},attrs:{"src":row.headimg}}),_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex":"1"}},[_vm._v(_vm._s(row.nickname))]),_c('div',{staticStyle:{"width":"158px","text-align":"right"}},[_vm._v(_vm._s(row.create_date))])]),_c('div',{staticClass:"shenluehao"},[_vm._v(_vm._s(row.desc.replace(/<[^>]*>?|查看详情/g, '')))])])],1)]}}])})],1),_c('el-card',{attrs:{"body-style":{ height:'30vh' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("团队公告")])]),_c('com-table',{attrs:{"columns":[],"data":{
        url:'/xapi/user.group/noticeList',
        params:{type:6}
      },"height":"28vh","list-type":'list',"border":""},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',[_c('div',{staticStyle:{"display":"flex","font-size":"80%","justify-content":"space-between"}},[_c('div',[(row.status === 0)?_c('span',{staticStyle:{"width":"8px","height":"8px","border-radius":"50%","margin-right":"5px","background-color":"#F56C6C","display":"inline-block"}}):_vm._e(),_vm._v(_vm._s(row.title))]),_c('div',{staticStyle:{"color":"#C0C4CC"}},[_vm._v(_vm._s(row.create_date))])]),_c('div',{staticClass:"shenluehao",domProps:{"innerHTML":_vm._s(row.content)}})])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }