<template>
  <el-row>
    <el-col :span="10" style="height: 100vh">
      <el-card :body-style="{ height:'80vh' }">
        <div slot="header" class="clearfix">
          <span>成员排行榜</span>
        </div>

        <!-- <com-table
            ref="acTable"
            :data="{
                  url:'/xapi/user.group/memberRank',
                  params:{...$route.params,type:2}
                }"
            :pageOptions="false"
            :default-sort = "{prop: 'right_count', order: 'descending'}"
            :parseMethod="({data} , next)=>{next(data.data,data.total)}"
            :pageSize="20"
            height="70vh"
            :rowStyle="()=>{return {cursor:'pointer'};}"
            @sort-change="({prop,order})=>{$refs.acTable.reset({[prop+'_ord']:order==='descending'? 2 : 1});}"
            @cell-click="(row)=>{$handleRoute({user_id:row.user_id},'TeamUserInfoData')}"
            :columns="[
                {prop:'realname',label:'用户名'},
                {prop:'right_count',label:'全部AC题目数',width:'160' , align:'center' , sortable:true},
                {prop:'right_lv',label:'测试点得分率',width:'160' , align:'center'},
            ]"
        >
        </com-table> -->
        <com-table
            ref="acTable"
            :data="{
                  url:'/xapi/user.group/memberRank',
                  params:{...$route.params,type:2}
                }"
            :pageOptions="false"
            :default-sort = "{prop: 'right_count', order: 'descending'}"
            :parseMethod="({data} , next)=>{next(data.data,data.total)}"
            :pageSize="20"
            height="78vh"
            :rowStyle="()=>{return {cursor:'pointer'};}"
            @sort-change="({prop,order})=>{$refs.acTable.reset({[prop+'_ord']:order==='descending'? 2 : 1});}"
            :columns="[
                {prop:'realname',label:'用户名'},
                {prop:'right_count',label:'全部AC题目数',width:'160' , align:'center' , sortable:true},
                {prop:'right_lv',label:'测试点得分率',width:'160' , align:'center'},
            ]"
        >
        </com-table>
      </el-card>
      <el-card :body-style="{ height:'30vh' }">
        <div slot="header" class="clearfix">
          <span>团队数据统计</span>
        </div>
        <el-row>
          <el-col :span="12">
            <p>评测提交题目数：{{ groupState.quest_count || 0 }}</p>
            <p>AC提交数：{{ groupState.right_count || 0 }}</p>
          </el-col>
          <el-col :span="12">
            <p>评测提交次数：{{ groupState.total_count || 0 }}</p>
            <p>AC测试点数：{{ groupState.right_lv || 0 }}</p>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :span="13" :offset="1">
      <el-card :body-style="{ padding: '0 0 20px 0',height:'80vh' }" class="show-border" >
        <div slot="header" class="clearfix">
          <span>团员动态</span>
        </div>
        <form-create v-model="trendWhere" style="padding: 0 20px;" :inline="true" :label-width="'40px'" :components="[
              {name:'form-item',props:{prop:'type',label:'类型' , type:'select', multiple:true,filterable:false,data:{ url:'/xapi/user.group/options/type/trend_type' }
                }
              },
              {name:'form-item',props:{prop:'user_id',label:'团员' , type:'select', multiple:true,remote:true,filterable:true,data:{
                      url:'/xapi/user.group/memberList'
                      ,params:{...$route.params}
                      ,labelOptions:{label:'realname',value:'user_id'}
                  }
                }
              },
          ]"
        >
          <el-form-item class="operate" slot="after">
            <el-button type="primary" size="mini" style="--color-primary: #fc8563;--color-primary-8:#fc8563e5" @click="$refs.trendTable.reset(trendWhere)">查询</el-button>
          </el-form-item>
        </form-create>
        <com-table ref="trendTable" :columns="[]" :data="{
          url:'/xapi/user.group/trendList'
        }"
                    height="76vh"
                   :list-type="'list'"
                   border
        >
         <template slot-scope="{row}">
           <div style="display: flex;height: 60px;line-height: 30px;padding:0 20px">
             <el-avatar style="display: block;margin-top: 10px;margin-right: 10px" :src="row.headimg"></el-avatar>
             <div style="flex: 1;">
               <div  style="display: flex;"><div style="flex: 1;">{{row.nickname}}</div><div style="width: 158px;text-align: right;">{{row.create_date}}</div></div>
               <!-- <div class="shenluehao" v-html="row.desc"></div> -->
               <div class="shenluehao">{{ row.desc.replace(/<[^>]*>?|查看详情/g, '') }}</div>
             </div>
           </div>
         </template>
        </com-table>
      </el-card>
      <el-card  :body-style="{ height:'30vh' }">
        <div slot="header" class="clearfix">
          <span>团队公告</span>
        </div>
        <com-table  :columns="[]" :data="{
          url:'/xapi/user.group/noticeList',
          params:{type:6}
        }"
            height="28vh"

        :list-type="'list'"
         border
        >
          <template slot-scope="{row}">
            <div >
              <div  style="display: flex;font-size:80%;justify-content: space-between">
                <div><span style="width:8px;height:8px;border-radius: 50%;margin-right:5px;background-color: #F56C6C;display: inline-block;" v-if="row.status === 0"></span>{{row.title}}</div>
                <div style="color: #C0C4CC;">{{row.create_date}}</div>
              </div>
              <div class="shenluehao"  v-html="row.content"></div>
            </div>
          </template>
        </com-table>
      </el-card>
      
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    team_id:{
      default: () => 0,
    }
  },
  data(){
    return {
      groupState:{
        quest_count:0,
        right_count:0,
        total_count:0,
        right_lv:0,
      },
      trendWhere: {},
    };
  },
  methods: {
    routeUpdate(){
      this.getGroupState();
    },
    getGroupState(){
      this.$http.post('/xapi/user.group/groupState' , {team_id:this.team_id,fields:Object.keys(this.groupState)}).then(({data})=>{
        this.groupState = {...this.groupState,...data}
      });
    },
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  // .infinite-list-wrapper{
  //   // height: 94vh !important;
  // }
  .el-row{
    .el-col .el-card:not(.show-border) {
      .el-card__header{
        border-bottom:0;
      }
    }
  }
  .el-card{
    > p:not(:last-child){
      margin-bottom: 15px;
    }
    .el-card__header{
      font-weight: 700;
    }
  }
  .el-card:not(:last-child){
    margin-bottom: 15px;
  }
  .el-card__body .el-table tr:first-of-type th.el-table__cell{
    padding-top:0;
  }
  .infinite-list-wrapper ul li:not(:last-child){
    margin-bottom: 0 !important;
  }
}
.show-border .el-form--inline .el-form-item__content{
  width: 150px;
}
</style>